.custom-theme {
  // Custom variables (override from themeBuilder module)
  --primary: #{$primary};
  --secondary: #{$secondary};
  --body-color: #{$body-color};
  --link-color: #{$link-color};
  --headings: #{$headings-color};
  --button-primary-bg-color: #{$primary};
  --button-primary-bg-color-hover: #{darken($primary, 7.5%)};
  --button-primary-color: #{$white};
  --button-primary-border-color: #{$primary};

  // Typography
  color: var(--body-color);

  a {
    color: var(--link-color);
  }

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--headings);
  }

  // Backgrounds
  .bg--primary {
    background-color: var(--primary);
  }

  // Sections menu nav
  #sections-nav {
    .section-item {
      color: var(--secondary);
      &.active {
        border-color: var(--primary) !important;
        i,
        &:after {
          color: var(--primary) !important;
        }
      }
    }
  }

  // Buttons
  .btn {
    &.btn-link {
      color: var(--link-color);
      &:hover {
        color: var(--button-primary-bg-color-hover);
      }
    }
    &.btn-primary {
      background-color: var(--button-primary-bg-color);
      border-color: var(--button-primary-border-color);
      &:hover {
        background-color: var(--button-primary-bg-color-hover);
      }
    }
    &.btn-outline-primary {
      color: var(--button-primary-bg-color);;
      border-color: var(--button-primary-border-color);
      &:hover {
        color: $white;
        background-color: var(--button-primary-bg-color);
      }
    }
  }

  .button--primary {
    color: var(--link-color);
    &:hover {
      color: var(--button-primary-bg-color-hover);
    }
  }

  // Button icon (back step)
  .btn-icon {
    border-color: var(--primary);
    color: var(--primary);
    &:hover {
      background-color: var(--primary);
    }
  }

  // Form
  form {
    
    @media (max-width: 1200px) {
      width: 100%;
    }
    max-width: 800px;

    .form-group {
      label + button i {
        color: var(--primary);
      }

      .pill {
        border-color: var(--primary);
        color: var(--primary);
        &.pill--checked {
          background-color: var(--primary);
          color: $white;
          &.pill--disabled {
            border: 1px solid $gray-600;
            opacity: 0.6;
            background-color: $gray-600;
            color: $white;
          }
        }
        &.pill--disabled {
          border: 1px solid $gray-600;
          opacity: 0.6;
          color: inherit;
        }
      }
    }
  }

  // Spinner
  .spinner {
    svg {
      stroke: var(--primary);
      fill: var(--primary);
    }
  }

  // Onfido
  #onfido {
    .onfido-sdk-ui-PageTitle-titleSpan {
      color: var(--headings);
    }
    .onfido-sdk-ui-PageTitle-subTitle {
      color: var(--body-color);
    }
  }
}
