//
// Form
// --------------------------------------------------

@import 'List';
@import 'OnfidoField';
@import 'DrillDownField';
@import 'AdvancedSelectField';
@import 'DateField';
@import 'ListCardMultiselect';
@import 'ObjectCatalog';
@import 'SignatureArea';

@import 'variants/variants';

.form-group {
  &:last-child {
    margin-bottom: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  label + button {
    margin-left: 2px;
    i {
      color: $primary;
      margin-bottom: 1px;
    }
  }
  .form-control-underline {
    background-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    &:disabled {
      background-color: $gray-200;
    }
  }
  .form-check{
    *:last-child {
      margin-bottom: 0;
    }
  }
  .form-asset {
    margin-top: 10px;
  }
  .custom-file-label{
    z-index: 0;
  }
}
