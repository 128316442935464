//
// Typography
// 
// Fonts, inline selectors, etc.
// --------------------------------------------------

// Poppins font-family: 'Poppins';
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap');

// Material design icons: https://material.io/resources/icons/?style=baseline
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

// Material icons
.material-icons,
.material-icons-outlined{
  display: inline-flex;
  vertical-align: middle;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}