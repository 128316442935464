// Spinner

.spinner {
  display: flex;
  &.center {
    align-items: center;
    justify-content: center;
  }
  &.left {
    align-items: flex-start;
    justify-content: left;
  }
  &.right {
    align-items: flex-start;
    justify-content: right;
  }
  &.inline{
    display: inline-flex;
    &:last-child{
      margin: 0 10px;
    }
  }
  &.middle {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &.full {
    background-color: rgba($white, .9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }
  svg {
    stroke: $primary;
    fill: $primary;
  }
}