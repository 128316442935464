// Region: Main

#main {
  .layout--1 & {
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 70px ($grid-gutter-width/2) 20px;
    display: flex;
    flex-direction: column;
    flex: none;
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0 45px 0 15px;
      flex: 1;
    }
    .region__content{
      display: flex;
      flex-direction: column;
      flex: 1;
      @include media-breakpoint-up(md) {
        padding: 0 15px;
        flex-basis: 0;
        overflow: auto;
      }
    }
    .flow-title {
      margin-bottom: 25px;
      h1 {
        text-align: center !important;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    #steps {
      @include media-breakpoint-up(md) {
        padding: 45px 0;
      }
     
    }
  }
  .layout--3  & {
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 70px ($grid-gutter-width/2) 20px;
    display: flex;
    flex-direction: column;
    flex: none;
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: ($grid-gutter-width/2) 45px 0 15px;
      flex: 1;
    }
    .region__content{
      display: flex;
      flex-direction: column;
      flex: 1;
      @include media-breakpoint-up(md) {
        padding: 0 15px;
        flex-basis: 0;
        overflow: auto;
      }
    }
    .flow-title {
      margin-bottom: 25px;
      h1 {
        text-align: center !important;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
  .layout--2 & {
    padding: ($grid-gutter-width/2) ($grid-gutter-width*2) $grid-gutter-width;
    @include media-breakpoint-down(sm) {
      padding: 70px ($grid-gutter-width/2) 20px;
    }
    .flow-title {
      margin-bottom: $grid-gutter-width;
      h1 {
        text-align: center !important;
      }
    }
  }
  .layout--4 & {
    padding: ($grid-gutter-width/2) ($grid-gutter-width*2) $grid-gutter-width;
    @include media-breakpoint-down(sm) {
      padding: 30px ($grid-gutter-width/2) 20px;
    }
    .flow-title {
      margin-bottom: $grid-gutter-width;
      h1 {
        text-align: center !important;
      }
    }
  }
}