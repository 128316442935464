// Region: Footer

#footer {
  padding: 20px ($grid-gutter-width/2);
  @include media-breakpoint-down(sm) {
    padding: 0 ($grid-gutter-width/2) ($grid-gutter-width/2);
  }
  .layout--1 &{
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .layout--3 &{
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}