// Step form

#step-form {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  .form-actions {
    margin-top: rem-calc(45);
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      margin-top: auto;
      > button {
        margin-top: 0;
      }
    }
    > button {
      &:first-child:not(.btn-primary) {
        margin-right: rem-calc(10);
      }
    }
  }
}