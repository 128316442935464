// PDF Book

  
.pdf-book{
  // margin-left: auto;
  // margin-right: auto;
  width: fit-content;
  margin-bottom: $grid-gutter-width/2;
  position: relative;
  &:hover {
    .pdf-book__nav {
      opacity: 1;
    }
  }
  .pdf-book__nav {
    @include box;
    @include shadow;
    width: fit-content;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    bottom: 30px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    button {
      padding: 10px;
      &:hover {
        background-color: #e6e6e6;
      }
      &:first-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.pdf-book__document {
  &.pdf-book__document--preview {
    .pdf-book__page {
      width: auto !important;
      height: auto !important;
      > canvas {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        @include box;
        @include shadow;
        @include media-breakpoint-up(md) {
          max-width: 400px;
        }
      }
    }
  }
  &.pdf-book__document--full {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pdf-book__page {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0
      }
      > canvas {
        @include shadow;
        width: auto !important;
        height: auto !important;
        max-width: 100%;
      }
    }
  }
}