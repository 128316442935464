// Onfido

.onfido-field {
  height: 100%;
  @extend .col-lg-9;
  @extend .col-xl-6;
}

#onfido {
  height: calc(100% - 65px);
  min-height: 345px;
  @include media-breakpoint-up(md) {
    min-height: 570px;
  }
  .onfido-sdk-ui-Modal-inner {
    @include box;
    @include shadow;
    font-size: $font-size-base;
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: 100%;
    position: relative;
  }
  .onfido-sdk-ui-PageTitle-titleWrapper {
    margin-top: rem-calc(16);
  }
  .onfido-sdk-ui-PageTitle-titleSpan {
    font-size: $h3-font-size;
    color: $secondary;
  }
  .onfido-sdk-ui-PageTitle-subTitle {
    font-size: $font-size-base;
    color: $body-color;
  }
  .onfido-sdk-ui-Welcome-text {
    margin: auto;
  }
  .onfido-sdk-ui-Uploader-crossDeviceInstructionsContainer{
    > div:nth-child(2){
      display: flex;
      flex-direction: column;
    }
  }
  .ods-button {
    &.-action--primary{
      @extend .btn;
      @extend .btn-primary;
      width: 100%;
    }
    &.-action--secondary{
      @extend .btn;
      @extend .btn-outline-secondary;
      width: 100%;
    }
  }
  .onfido-sdk-ui-Theme-link {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-link;
  }
  .onfido-sdk-ui-Button-button:not(.onfido-sdk-ui-crossDevice-CrossDeviceLink-btn) {
    @extend .btn;
    margin-top: 15px;
    &.onfido-sdk-ui-Button-button-primary{
      @extend .btn-primary;
    }
    &.onfido-sdk-ui-Button-button-secondary{
      @extend .btn-outline-secondary;
    }
  }
  .onfido-sdk-ui-Theme-footer {
    margin-bottom: rem-calc(16);
    z-index: 0;
  }
}