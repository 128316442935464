// Component: Box

.box {
  @include box;
  @include shadow;
  &.box--error {
    border-style: solid;
    border-color: lighten($danger, 20%);
  }
  &.box--active {
    border-style: solid;
    border-color: $primary;
  }
  &.box--asset {
    @include shadow-hover;
    max-width: 150px;
    .box__top i{
      font-size: rem-calc(40);
    }
    .box__body {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 10px;
    }
  }
  .box__left {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.box__left--sm{
      @include media-breakpoint-up(md) {
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
      }
    }
  }
  .box__top {
    color: $white;
    padding: ($grid-gutter-width/2) $grid-gutter-width;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box__header {
    padding: ($grid-gutter-width/2);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box__title {
    color: $secondary;
    font-weight: $font-weight-bold;
    font-size: rem-calc(16px);
    &.box__title--error {
      color: $danger;
    }
  }
  .box__body {
    padding: ($grid-gutter-width/2);
    word-break: break-word;
    &:not(:first-child) {
      border-top: 1px solid $gray-300;
    }
  }
  .box__footer {
    border-top: 1px solid $gray-300;
    padding: ($grid-gutter-width/2) 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
}