body.off-canvas--open {
  height: 100%;
  overflow: hidden;
}

#off-canvas{
  background-color: $body-bg;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  transition: width .3s ease-in-out;
  z-index: 98;
  &.hidden,
  &.hidding{
    width: 0;
  }
  &.open,
  &.opening {
    width: 100%;
    .layout--1 & {
      width: 0;
    }
  }
  .region__content {
    padding: 70px ($grid-gutter-width/2) ($grid-gutter-width/2);
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    @include media-breakpoint-up(md) {
      padding: ($grid-gutter-width/2)
    }
    #org-logo {
      margin-bottom: $grid-gutter-width;
    }
    .org-about {
      margin-top: auto;
    }
  }
}