// Component: FlowTitle

.flow-title {
  h1 {
    img {
      max-width: 32px;
      margin-right: 7px;
    }
    margin-bottom: 0;
  }
}