// Layout - Stepper

.layout--3 {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    height: 100vh;
  }
  .off-canvas--open &{
    height: 100%;
  }
}