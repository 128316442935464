// Region: Sidebar primary

#sidebar-primary {
  background-color: #FFFFFF;
  padding: $grid-gutter-width $grid-gutter-width 20px;
  display: flex;
  flex-direction: column;
  .region__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
  }
  .layout--1 & { 
    #org-logo {
      margin-bottom: $grid-gutter-width;
    }
    .org-about {
      margin-top: auto;
    }
  }
  .layout--3 & { 
    #org-logo {
      margin-bottom: $grid-gutter-width;
    }
    .org-about {
      margin-top: auto;
    }
  }
}