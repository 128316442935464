// Component: NavToogle

#nav-toggle {
  margin-right: 15px;
  &:focus {
    outline: none;
  }
  i {
    font-size: rem-calc(35);
    @include media-breakpoint-up(md) {
      font-size: rem-calc(38);
    }
  }
}
