.list-card {
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  &:hover {
    @include shadow-hover;
  }
  .list-card__banner {
    margin-bottom: 10px;
    width: 100%;
    height: 100px;
    &:empty {
      margin: 0;
      height: 0;
    }
    img { 
      object-fit: scale-down;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}
