.signature-area {
  .signature-area__box {
    padding: 10px 10px 15px;
    max-width: 100%;
    position: relative;
    border: $gray-400 solid 1px;
    background-color: $white;
  }
  .signature-area__pad {
    height: 200px;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      height: 350px;
    }
    canvas {
      width: 100%;
      height: 100%;
    }
  }
  .signature-area__body {
    margin-top: -15px;
    text-align: center;
    padding-top: 15px;
    border-top: $gray-400 solid 1px;
  }
  .signature-area__footer {
    text-align: center;
    padding-top: 15px;
    border-top: $gray-300 solid 1px;
  }
}
