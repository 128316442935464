// Variant: Pill

.pill {
  background-color: transparent;
  color: $primary;
  cursor: pointer;
  display: inline-block;
  padding: $input-btn-padding-y $input-btn-padding-x;
  border: 1px solid $primary;
  border-radius: 1rem;
  margin: 0 5px 8px 0;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.pill--disabled {
    cursor: default;
  }
  &.is-invalid {
    border-color: $danger;
    color: $danger;
  }
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  + input[type="checkbox"]:disabled{
    display:none;
  }

}