//
// Default Variables
//
// Modify this file to provide default Bootstrap Framework variables. The
// Bootstrap Framework will not override any variables defined here because it
// uses the `!default` flag which will only set their default if not already
// defined here.
// --------------------------------------------------

// Color system

$primary:         #0279FE;
$secondary:       #042C5C;

// Body
//
// Settings for the `<body>` element.

$body-bg:         #F6F9FE;
$body-color:      #2C2C2C;

// Links
//
// Style anchor elements.

$link-color:      $primary;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-lighter:         400;
$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                1.5rem;
$h2-font-size:                1.375rem;
$h3-font-size:                1.25rem;
$h4-font-size:                1.125rem;
$h5-font-size:                1rem;
$h6-font-size:                0.875rem;

$headings-font-weight:        $font-weight-bold;
$headings-line-height:        1.2;
$headings-color:              $secondary;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-responsive-font-sizes:          true;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               4px;
$border-radius-lg:            4px;
$border-radius-sm:            4px;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-padding-x:                0;
$dropdown-padding-y:                0;
$dropdown-item-padding-x:           15px;
$dropdown-item-padding-y:           7px;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y-lg:      .625rem;
$input-btn-padding-x-lg:      1.25rem;
$input-btn-font-size-lg:      $font-size-base;
$input-placeholder-color:   #ced4da;
