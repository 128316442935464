//
// Grid
// --------------------------------------------------

.grid--vertical {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  align-items: stretch;
  .scrollable-wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    .scrollable-block {
      overflow: auto;
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-direction: column;
    }
  }
}