// DrillDown

.drilldown {
  .drilldown-list {
    li{
      margin-bottom: 7px
    }
    .drilldown-list__nav,
    .drilldown-list__option {
      @include shadow-hover;
      background: $white;
      color: $secondary;
      font-size: rem-calc(16px);
      font-weight: $font-weight-bold;
      text-align: left;
      text-decoration: none;
      padding: 12px 10px;
      width: 100%;
      border-style: solid;
      border-color: transparent;
      border-width: 1px;
      border-radius: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &:after {
        content: 'navigate_next';
        color: inherit;
        font-family: 'Material Icons';
        font-size: rem-calc(30);
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        margin-left: 15px;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
      }
      &:hover {
        color: $primary
      }
    }
    .drilldown-list__option{
      &.drilldown-list__option--selected {
        color: $primary;
        &:after {
          content: 'radio_button_checked';
        }
      }
      &:after {
        content: 'radio_button_unchecked';
      }
    }
  }
}