//
// Regions
// --------------------------------------------------

@import 'OffCanvas';
@import 'Header';
@import 'SidebarPrimary';
@import 'Main';
@import 'Footer';

.region {
  &.region--highlight{
    border: 1px dashed red;
    position: relative;
  }
}

.region__info {
  display: none;
  .region-highlight & {
    background-color: rgba(220, 53, 69, .9);
    padding: 10px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    .region--name {
      font-size: rem-calc(12);
      color: white;
    }
  }
}
