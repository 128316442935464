// Region: Header

#header {
  padding: ($grid-gutter-width/2) $grid-gutter-width;
  z-index: 99;
  @include media-breakpoint-down(sm) {
    background-color: $white;
    width: 100%;
    padding: 7px ($grid-gutter-width/2);
    position: fixed;
    top: 0;
    box-shadow: 0 -1px 23px 2px rgba(0, 0, 0, 0.08);
  }
  .layout--1 &{
    @include media-breakpoint-up(md) {
      display: none;
    }
    .region__content{
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        justify-content: space-between;
      }
    }  
    #org-logo {
      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }
    #flow-title {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      h1 {
        margin-bottom: 0;
      }
    }
    #language-switcher {
      @include media-breakpoint-up(md) {
        margin-left: auto;
      }
    }
    #nav-toggle {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
  .layout--3  &{
    .region__content{
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        justify-content: space-between;
      }
    }  
    #org-logo {
      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }
    #flow-title {
      @include media-breakpoint-down(sm) {
        display: none;
      }
      h1 {
        margin-bottom: 0;
      }
    }
    #language-switcher {
      @include media-breakpoint-up(md) {
        margin-left: auto;
      }
    }
    #nav-toggle {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
  .layout--2 &{
    .region__content{
      display: flex;
      align-items: center;
      justify-content: center;
    }  
    .flow-title {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $gray-400;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      h1 {
        margin-bottom: 0;
      }
    }
  }
  .layout--4 &{
    .region__content{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }  
    .flow-title {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $gray-400;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      h1 {
        margin-bottom: 0;
      }
    }
  }
}