// Mixins

// Box
@mixin box {
  background: $white;
  color: $body-color;
  border: none;
  border-radius: 10px;
}

// General shadow
@mixin shadow ($color: #000000) {
  box-shadow: 0 17px 23px 2px rgba($color, 0.08);
}

// Shadow for card-style elements with hover effect
@mixin shadow-hover{
  box-shadow: 0 17px 23px 2px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover{
    box-shadow: 0 20px 23px 2px rgba(0, 0, 0, 0.09), 0 20px 23px 2px rgba(0, 0, 0, 0.09);
  }
}

// Material icon attributes
@mixin material-icon ($icon: 'autorenew') {
  content: $icon;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// Circle
@mixin circle($size, $bg-color, $border-color, $color: $white, $radius: 50%) {
  width: $size;
  height: $size;
  background-color: $bg-color;
  border: 1px solid $border-color;
  text-align: center;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Generates appearance for a given element
@mixin x-appearance ($value) {
	-webkit-appearance: $value;
	   -moz-appearance: $value;
	        appearance: $value;
}