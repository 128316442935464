// Component: SectionsNav

#sections-nav {
   li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .section-item {
      @include shadow-hover;
      background: $white;
      color: $body-color;
      text-align: left;
      text-decoration: none;
      padding: 15px 10px;
      width: 100%;
      border-style: solid;
      border-color: transparent;
      border-width: 1px;
      border-radius: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:after {
        content: 'navigate_next';
        color: inherit;
        font-family: 'Material Icons';
        font-size: rem-calc(30);
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
      }
      &:disabled {
        opacity: 0.5;
        &:after {
          content: 'lock' !important;
          font-size: rem-calc(23);
        }
      }
      &.active,
      &.incompleted,
      &.completed,
      &.invalid {
        &:after {
          font-size: rem-calc(25);
        }
      }
      &.active {
        border-color: $blue !important;
        i, &:after {
          color: $blue !important;
        }
        &:after {
          content: 'radio_button_checked';
        }
      }
      &.incompleted {
        border-color: $warning;
        i, &:after {
          color: $warning;
        }
        &:after {
          content: 'warning';
        }
      }
      &.completed {
        border-color: $success;
        i, &:after {
          color: $success;
        }
        &:after {
          content: 'task_alt';
        }
      }
      &.invalid {
        border-color: $danger;
        i, &:after {
          color: $danger;
        }
        &:after {
          content: 'error';
        }
      }
      span {
        color: $body-color;
        padding: 0 10px;
        flex: 1;
      }
      i {
        color: inherit;
      }
    }
  }
}