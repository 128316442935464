/*
 * Portal v2 - PEFAI
 * Author: FED Team
 */

@import 'vendors/vendors';
@import 'global';
@import 'utils/utils';
@import 'components/components'; 
@import 'base/base';
@import 'views/views';
@import 'grid/grid';
@import 'layouts/layouts';
@import 'regions/regions';
@import 'theme/custom';
