.list-card-multiselect {
  .list-card-multiselect__grid {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 15px;
    grid-row-gap: 10px;
    @media (min-width: 992px) {
      padding: 0;
      grid-template-columns: repeat(2, minmax(280px, 320px));
      grid-column-gap: 10px;
    }
  }
}
