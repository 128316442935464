// Base: Buttons

.button {
  &.button--reset {
    @include x-appearance(none);
    background: transparent;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    padding: 0;
    border: none;
    border-radius: 0;
    &:hover {
      cursor: pointer;
    }
  }
  &.button--box {
    @include box;
    @include shadow;
  }
  &.button--block {
    display: block;
    width: 100%;
  }
  &.button--primary {
    color: $primary;
    &:hover {
      color: darken($primary, 15%);
    }
  }
  &.button--danger {
    color: $danger;
    &:hover {
      color: darken($danger, 15%);
    }
  }
}

@include media-breakpoint-only(xs) {
  .btn-xs-block {
      display: block;
      width: 100%;
  }
  input[type="submit"].btn-xs-block,
  input[type="reset"].btn-xs-block,
  input[type="button"].btn-xs-block {
    width: 100%;
  }
  .btn-block + .btn-xs-block,
  .btn-xs-block + .btn-block,
  .btn-xs-block + .btn-xs-block {
    margin-top: 0.5rem;
  }
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
  input[type="submit"].btn-sm-block,
  input[type="reset"].btn-sm-block,
  input[type="button"].btn-sm-block {
    width: 100%;
  }
  .btn-block + .btn-sm-block,
  .btn-sm-block + .btn-block,
  .btn-sm-block + .btn-sm-block {
    margin-top: 0.5rem;
  }
}

@include media-breakpoint-down(md) {
  .btn-md-block {
    display: block;
    width: 100%;
    border-radius: 0;
  }
  input[type="submit"].btn-md-block,
  input[type="reset"].btn-md-block,
  input[type="button"].btn-md-block {
    width: 100%;
  }
  .btn-block + .btn-md-block,
  .btn-md-block + .btn-block,
  .btn-md-block + .btn-md-block {
    margin-top: 0.5rem;
  }
}

@include media-breakpoint-down(lg) {
  .btn-lg-block {
    display: block;
    width: 100%;
    border-radius: 0;
  }
  input[type="submit"].btn-lg-block,
  input[type="reset"].btn-lg-block,
  input[type="button"].btn-lg-block {
    width: 100%;
  }
  .btn-block + .btn-lg-block,
  .btn-lg-block + .btn-block,
  .btn-lg-block + .btn-lg-block {
    margin-top: 0.5rem;
  }
}