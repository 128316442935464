// Component: BtnIcon

.btn-icon {
  background-color: transparent;
  color: $primary;
  border-color: $primary;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover{
    background-color: $primary;
    color: $white !important;
  }
  > i, span {
    color: inherit;
  }
}