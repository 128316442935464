.object-catalog {
  position: relative;

  .object-catalog__selection {
    @extend .box;
    padding: 0;
    margin-top: 10px;
    position: absolute;
    width: 100%;
    list-style-type: none;
    overflow: hidden;

    li {
      padding: 10px 8px;
      font-size: 1rem;
      cursor: pointer;

      &:hover{
          background: $primary;
          color: $body-bg;
      }

      .media-body {
        align-self: center;
      }

      img {
        width: 32px;
      }

      p {
        margin-bottom: 0;
        line-height: 1;
        font-size: 1rem;
      }
    }

    .object-catalog_selection__details {
      margin-top: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        &:after {
          margin: 0 5px;
          content: '|';
        }
      }

      li:last-child {
        &:after {
          content: '';
        }
      }
    }
  }

  .object-catalog__filters {
    padding: 0;
    margin-top: 10px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li{
        border-radius: 12px;
        padding: 4px 8px;
        margin-right: 8px;
        background: $primary;
        color: $body-bg;
        font-size: 0.8rem;
        overflow: hidden;
        cursor: pointer; 
        &:after{
            border-radius: 10px;
            margin-left: 8px;
            padding: 0 5px;
            background: $body-bg;;
            text-align: center;
            content: "x";
            color: $primary;
        }
    }
  }
}
